import { useState, useMemo } from 'react';
import classNames from 'classnames';

import config from 'config';
import Star from '@icons/Star';
import { useIntl } from 'utils/intl';
import createStyleVariables from 'utils/createStyleVariables';

import classes from './StarRating.module.scss';
import messages from './StarRating.messages';

const { maxVariantRating } = config;

type Props = {
  rating?: number | null;
  maxRating?: number;
  starSize?: number;
  starMargin?: number;
  starsCount?: number;
  withoutNumberRating?: boolean;
  isEdit?: boolean;
  onClick?: (rating: number) => void;
};

const getPercent = (rating: number, maxRating: number): number => Math.round((rating / maxRating) * 1000) / 10;

const StarRating = ({
  rating,
  maxRating = maxVariantRating,
  starSize = 13,
  starMargin = 2,
  starsCount = maxRating,
  onClick = () => {},
  withoutNumberRating = false,
  isEdit = false,
}: Props): JSX.Element => {
  const ratingInPercent = rating ? getPercent(rating, maxRating) : 0;
  const starsWidth = starsCount * (starSize + 2 * starMargin);
  const intl = useIntl();

  const [editRatingPercent, setEditRatingPercent] = useState(ratingInPercent);

  const stars = useMemo(
    () => (
      <div className={classes.stars}>
        {[...Array(starsCount)].map((_, index) => (
          <Star
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={classNames(classes.star, { [classes.cursorPointer]: isEdit })}
            onMouseEnter={() => setEditRatingPercent(getPercent(index + 1, maxRating))}
            onClick={() => onClick(index + 1)}
          />
        ))}
      </div>
    ),
    [isEdit, maxRating, starsCount, onClick]
  );

  const title: string | undefined = useMemo(() => {
    if (isEdit) {
      return undefined;
    }
    return rating ? `${rating}/${maxRating}` : intl.formatMessage(messages.emptyRating);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={classes.wrapper}
      title={title}
      data-nosnippet
      style={createStyleVariables({
        starSize: `${starSize}px`,
        starsWidth: `${starsWidth}px`,
        starMargin: `${starMargin}px`,
        ratingInPercent: `${isEdit ? editRatingPercent : ratingInPercent}%`,
      })}
      onMouseLeave={() => setEditRatingPercent(ratingInPercent)}
    >
      <div className={classes.starsRating}>
        <div className={classes.activeStars}>{stars}</div>
        {stars}
      </div>
      {!withoutNumberRating && <div className={classes.numberRating}>{!!rating && rating.toFixed(1)}</div>}
    </div>
  );
};

export default StarRating;
