import classNames from 'classnames';

import Price from 'types/Price';
import { useIntl } from 'utils/intl';
import { NumberFormatOptions } from 'utils/formatAmount';

import { formatAmount } from './Amount.utils';
import messages from './Amount.messages';
import classes from './Amount.module.scss';

type Props = {
  type?: 'netto' | 'brutto';
  size?:
    | 'nano'
    | 'micro'
    | 'tiny'
    | 'little'
    | 'xSmall'
    | 'small'
    | 'compact'
    | 'medium'
    | 'large'
    | 'great'
    | 'xLarge'
    | 'xxLarge'
    | 'huge'
    | 'xHuge';
  price: Nullable<Price>;
  isFaded?: boolean;
  isBolded?: boolean;
  withFrom?: boolean;
  className?: string;
  options?: NumberFormatOptions;
};

const Amount = ({
  type,
  size = 'compact',
  price,
  className,
  isBolded = false,
  isFaded = false,
  withFrom = false,
  options,
}: Props): JSX.Element => {
  const intl = useIntl();
  const formattedAmount = price ? formatAmount(intl, price, type, options) : '-';

  return (
    <div
      className={classNames(classes.wrapper, className, classes[size], {
        [classes.isFaded]: isFaded,
        [classes.isBolded]: isBolded,
      })}
    >
      {`${withFrom ? `${intl.formatMessage(messages.from)} ` : ''}${formattedAmount}`}
    </div>
  );
};

export default Amount;
