import Price from 'types/Price';
import formatAmountUtil, { NumberFormatOptions } from 'utils/formatAmount';
import { IntlShape } from 'utils/intl';

import messages from './Amount.messages';

const MESSAGES_TO_TYPE_MAP = {
  netto: messages.netto,
  brutto: messages.brutto,
};

const formatAmount = (
  intl: IntlShape,
  price: Price,
  type?: 'netto' | 'brutto',
  options?: NumberFormatOptions
): string => {
  const formattedValue = formatAmountUtil(price, options);

  if (!type) {
    return formattedValue;
  }

  const message = MESSAGES_TO_TYPE_MAP[type];
  const formattedMessage = intl.formatMessage(message, { value: formattedValue });

  return formattedMessage;
};

export { formatAmount };
