import { Fragment } from 'react';
import classNames from 'classnames';
import Image from '@next/image';

import Arrow from 'assets/icons/arrow.svg';
import Typography from '@components/atoms/Typography';
import PossibleLink from '@components/atoms/PossibleLink';
import Link from '@components/atoms/Link';
import ProductBox from '@components/ProductBox';
import createStyleVariables from 'utils/createStyleVariables';

import { Props } from './HomepageProducts.types';
import classes from './HomepageProducts.module.scss';

const HomepageProducts = ({ homepageProducts }: Props) => (
  <>
    {homepageProducts.map(({ type, items, product, typeImage, href: typeHref }, index) => {
      const variant = index % 2 ? 'left' : 'right';
      const variantClass = {
        [classes.right]: variant === 'right',
        [classes.left]: variant === 'left',
      };

      const singleItem = items[0];

      return (
        <Fragment key={type}>
          <PossibleLink href={typeHref} underline="hover">
            <Typography
              className={classNames(classes.header, classes.mobileHeader)}
              variant="h2"
              renderAs="div"
              weight="700"
            >
              <Image
                height={22}
                width={22}
                objectFit="contain"
                hasFade={false}
                hasLazyLoad={false}
                hasBlendMode={false}
                alt={type}
                className={classes.icon}
                resolveConfig={{
                  preset: 'main_menu_icon',
                  productName: 'main_menu_icon',
                  extension: typeImage.extension,
                  name: typeImage.basename,
                  description: type,
                }}
              />
              {type}
            </Typography>
          </PossibleLink>
          <div className={classNames(classes.wrapper, variantClass)}>
            {items.length === 1 ? (
              <div className={classNames(classes.bigBox, variantClass)}>
                <div className={classes.bigImageWrapper}>
                  <Image
                    objectFit="contain"
                    alt={type}
                    resolveConfig={{
                      extension: singleItem.image.extension,
                      name: singleItem.image.basename,
                      preset: 'i',
                      productName: type,
                      width: singleItem.image.width,
                      height: singleItem.image.height,
                    }}
                    hasPreload={!index}
                    hasLazyLoad={false}
                    hasFade={false}
                  />
                </div>
                <div className={classes.bigBoxContent}>
                  <div className={classes.bigBoxTexts}>
                    <PossibleLink className={classes.bigBoxHeader} href={typeHref} underline="hover">
                      {singleItem.subName}
                    </PossibleLink>
                    <Typography variant="body2">{singleItem.description}</Typography>
                  </div>
                  <div className={classes.bigBoxItems}>
                    {singleItem.series.map((serie, i) => (
                      <Link
                        key={serie.name}
                        className={classNames(classes.button, classes.buttonGrid)}
                        href={serie.url}
                        component={!serie.hasRealLink ? 'span' : undefined}
                        style={createStyleVariables({
                          alpha: ((i % 3) + 2) * 0.05,
                        })}
                      >
                        <Typography className={classes.seriesName} variant="body2" renderAs="div">
                          {serie.name}
                        </Typography>
                        <Arrow className={classes.arrow} />
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.items}>
                {items.map(
                  ({ id, href, subName, description, series, image, hasRealLink: itemHasRealLink }, itemIndex) => (
                    <div
                      key={id}
                      style={createStyleVariables({
                        alpha: (itemIndex + 1) * 0.05,
                        boxWidth: `${100 / items.length}%`,
                      })}
                      className={classNames(classes.box, variantClass)}
                    >
                      <div className={classes.imageNameWrapper}>
                        <PossibleLink href={href} component={!itemHasRealLink ? 'span' : undefined}>
                          <Image
                            className={classes.imageName}
                            objectFit="contain"
                            alt={subName}
                            resolveConfig={{
                              extension: image.extension,
                              name: image.basename,
                              preset: 'svg',
                              productName: subName,
                              width: image.width,
                              height: image.height,
                            }}
                            hasPreload={!index}
                            hasLazyLoad={false}
                            hasFade={false}
                          />
                        </PossibleLink>
                        <div className={classes.texts}>
                          <PossibleLink href={href} underline="hover">
                            <Typography className={classes.subName} variant="body2" renderAs="div" weight="700">
                              {subName}
                            </Typography>
                          </PossibleLink>
                          <Typography variant="caption1" renderAs="div" lineHeight="18px">
                            {description}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.series}>
                        {series.map((serie, i) => (
                          <Link
                            key={serie.name}
                            className={classNames(classes.button, classes.buttonColumn)}
                            href={serie.url}
                            component={!serie.hasRealLink ? 'span' : undefined}
                            style={createStyleVariables({ alpha: (i + 1) * 0.05 })}
                          >
                            <Typography className={classes.seriesName} variant="body2" renderAs="div">
                              {!serie.nameMobile ? (
                                serie.name
                              ) : (
                                <>
                                  <span className={classes.mobile}>{serie.nameMobile}</span>
                                  <span className={classes.desktop}>{serie.name}</span>
                                </>
                              )}
                            </Typography>
                            <Arrow className={classes.arrow} />
                          </Link>
                        ))}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
            <div className={classes.product}>
              <PossibleLink
                className={variant === 'left' ? classes.headerLeftWrapper : ''}
                href={typeHref}
                underline="hover"
              >
                <Typography
                  className={classNames(classes.header, classes.desktopHeader)}
                  variant="h2"
                  renderAs="div"
                  weight="700"
                >
                  {type}
                </Typography>
              </PossibleLink>
              <div className={classNames(classes.squareHelper, variantClass)} />
              <div className={classNames(classes.productWrapper, variantClass)}>
                {product && <ProductBox {...product} align={variant} />}
              </div>
            </div>
          </div>
        </Fragment>
      );
    })}
  </>
);

export default HomepageProducts;
