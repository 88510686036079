import { useContext } from 'react';
import classNames from 'classnames';

import { Classes } from 'types/Classes';
import PriceType from 'types/Price';
import { ConfigContext } from '@providers/ConfigProvider';
import Amount from '@templates/Amount';
import Typography from '@components/atoms/Typography';
import { useFormatAmount } from 'utils/formatAmount';
import { FormattedMessage } from 'utils/intl';
import createStyleVariables from 'utils/createStyleVariables';

import classes from './Price.module.scss';
import messages from './Price.messages';

type Align = 'left' | 'right' | 'center';

type Props = {
  classes?: Classes<'root' | 'net' | 'gross' | 'oldGross' | 'extendedPriceInfo'>;
  netPrice: PriceType;
  grossPrice: PriceType;
  oldGrossPrice?: PriceType;
  withSlash?: boolean;
  withComplementary?: boolean;
  withFrom?: boolean;
  bruttoIsBolded?: boolean;
  align?: Align;
  mobileAlign?: Align;
};

const alignMap: Record<Align, string> = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};

const Price = ({
  classes: customClasses,
  netPrice,
  grossPrice,
  oldGrossPrice,
  align = 'left',
  mobileAlign = 'center',
  withSlash = false,
  withFrom = false,
  bruttoIsBolded = true,
  withComplementary = false,
}: Props): JSX.Element => {
  const { hasIndividualCustomers } = useContext(ConfigContext);
  const formatAmount = useFormatAmount();

  const hasDiscount =
    !hasIndividualCustomers && oldGrossPrice && oldGrossPrice.amount > 0 && oldGrossPrice.amount > grossPrice.amount;

  return (
    <div
      style={createStyleVariables({
        align: alignMap[align],
        mobileAlign: alignMap[mobileAlign],
      })}
      className={classNames(customClasses?.root, { [classes.wrapper]: !withSlash, [classes.linearWrapper]: withSlash })}
    >
      <div className={classNames({ [classes.netPrices]: !withSlash })}>
        <Amount
          type="netto"
          className={customClasses?.net}
          price={netPrice}
          size={withSlash ? 'xSmall' : 'little'}
          withFrom={withFrom}
        />
      </div>
      {withSlash && (
        <Typography className={classes.slash} variant="body2">
          /
        </Typography>
      )}
      <div className={classes.grossPrices}>
        <Amount
          type={hasDiscount ? undefined : 'brutto'}
          className={customClasses?.gross}
          price={grossPrice}
          size={withSlash ? 'xSmall' : 'compact'}
          withFrom={withFrom}
          isBolded={bruttoIsBolded}
        />
        {hasDiscount && (
          <div className={classNames(classes.oldGrossPrice, customClasses?.oldGross)}>
            {formatAmount(oldGrossPrice)}
          </div>
        )}
      </div>
      {withComplementary && (
        <>
          {withSlash && (
            <Typography className={classes.slash} variant="body2">
              /
            </Typography>
          )}
          <Typography
            variant="caption1"
            lineHeight="15px"
            className={classNames(customClasses?.extendedPriceInfo, { [classes.extendedPrice]: !withSlash })}
          >
            <FormattedMessage {...messages.extendedPrice} />
          </Typography>
        </>
      )}
    </div>
  );
};

export default Price;
