import { useContext } from 'react';
import classNames from 'classnames';

import { HolidaysContext } from '@providers/HolidaysProvider';
import NoSSR from '@misc/NoSSR';
import Typography from '@components/atoms/Typography';
import FormattedMessage from '@components/FormattedMessage';
import { getDaysToDelivery } from 'utils/getShipsUntilDate';

import messages from './DeliveryLabel.messages';
import classes from './DeliveryLabel.module.scss';

type Props = {
  className?: string;
  shipsUntil: string;
};

const DeliveryLabel = ({ className, shipsUntil }: Props): JSX.Element => {
  const { holidays } = useContext(HolidaysContext);

  const daysToDelivery = getDaysToDelivery(shipsUntil, holidays);

  return (
    <div className={classNames(classes.container, className)}>
      <NoSSR>
        <Typography
          variant="caption2"
          renderAs="div"
          className={classNames(classes.button, {
            [classes.tommorow]: daysToDelivery === 1,
          })}
          lineHeight="15.4px"
        >
          <FormattedMessage tagName="div" {...messages.button} />
          {daysToDelivery <= 2 ? (
            <FormattedMessage
              tagName="div"
              {...(daysToDelivery === 1 ? messages.buttonTommorow : messages.buttonAfterTommorow)}
            />
          ) : (
            <FormattedMessage tagName="div" {...messages.buttonAfterDays} values={{ days: daysToDelivery }} />
          )}
        </Typography>
      </NoSSR>
    </div>
  );
};

export default DeliveryLabel;
