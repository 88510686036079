import classNames from 'classnames';
import Image from '@next/image';

import { SimpleVariant } from 'types/Variant';
import Attribute from 'types/Attribute';
import Price from '@templates/ProductCard/components/Price';
import Typography from '@components/atoms/Typography';
import StarRating from '@components/StarRating';
import HtmlPreview from '@components/HtmlPreview';
import Link from '@components/atoms/Link';
import DeliveryLabel from '@components/DeliveryLabel';
import { FormattedMessage, useIntl } from 'utils/intl';
import createStyleVariables from 'utils/createStyleVariables';
import getPlainText from 'utils/getPlainText';

import classes from './ProductBox.module.scss';
import messages from './ProductBox.messages';

export type Props = SimpleVariant<'shipsUntil'> & {
  align?: 'left' | 'right';
  attributes: Attribute[];
  description: string;
  imageName?: string;
};

const ProductBox = ({
  align = 'right',
  name,
  href,
  rating,
  attributes,
  description,
  thumbnail,
  imageName = '',
  price,
  grossPrice,
  shipsUntil,
}: Props): JSX.Element => {
  const intl = useIntl();
  const alignClass = {
    [classes.right]: align === 'right',
    [classes.left]: align === 'left',
  };

  return (
    <div className={classes.wrapper}>
      <div className={classNames(classes.promoted, alignClass)}>
        <FormattedMessage {...messages.promoted} />
      </div>
      <div className={classNames(classes.content, alignClass)}>
        <div className={classes.column}>
          <Typography variant="h3" renderAs="div" className={classes.name}>
            <Link href={href}>
              <HtmlPreview htmlText={name} component="span" />
            </Link>
          </Typography>
          <div className={classes.starRating}>
            <StarRating rating={rating} />
          </div>
          <Price netPrice={price} grossPrice={grossPrice} mobileAlign="left" />
          {shipsUntil && <DeliveryLabel shipsUntil={shipsUntil} />}
        </div>
        <div className={classes.imageWrapper}>
          <Link href={href}>
            <Image
              objectFit="contain"
              alt={imageName}
              resolveConfig={{
                preset: 'p_thumb_1',
                extension: thumbnail.extension,
                name: thumbnail.basename,
                productName: imageName,
                width: thumbnail.width,
                height: thumbnail.height,
              }}
              hasLazyLoad={false}
              hasFade={false}
            />
          </Link>
        </div>
      </div>
      <div className={classes.rightContent}>
        <div
          className={classes.attributes}
          style={createStyleVariables({ attributesRows: Math.ceil(attributes.length / 2) })}
        >
          {attributes.map((attribute) => (
            <Typography className={classes.attribute} variant="caption1" renderAs="div" key={attribute.name}>
              <b>{attribute.name}</b>
              {`: ${attribute.value
                .map((value) => getPlainText(value) || intl.formatMessage(messages.none))
                .join(', ')}`}
            </Typography>
          ))}
        </div>
        <Typography variant="caption2" renderAs="div" lineHeight="15px">
          <HtmlPreview htmlText={description} component="span" />
        </Typography>
      </div>
    </div>
  );
};

export default ProductBox;
