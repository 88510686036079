import add from 'date-fns/add';
import isAfter from 'date-fns/isAfter';
import isEqual from 'date-fns/isEqual';
import isWeekend from 'date-fns/isWeekend';
import nextMonday from 'date-fns/nextMonday';
import toDate from 'date-fns/toDate';
import getTimezoneOffset from 'date-fns-tz/getTimezoneOffset';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import { Holiday } from 'types/models/Holiday';
import isBrowser from 'utils/isBrowser';

const getDateString = (date: Date): string => [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('/');

const parseWithLocalTimeOffset = (dateString: string): Date => {
  const currentOffset = getTimezoneOffset('Europe/Warsaw', new Date()) / 3600000;
  const winterOffset = getTimezoneOffset('Europe/Warsaw', new Date(new Date().getFullYear(), 11, 1)) / 3600000;

  return isBrowser ? add(new Date(dateString), { hours: winterOffset - currentOffset }) : new Date(dateString);
};

const omitFreeDays = (date: Date, holidays: Holiday[], time: string, now: Date = new Date()): Date => {
  let resultDate = toDate(date);

  // omit weekend
  if (isWeekend(resultDate)) {
    resultDate = nextMonday(resultDate);
  }

  // try get holiday that takes place on resultDate
  const holiday = holidays.find(({ dateStart: dateStartString, dateEnd: dateEndString }) => {
    const dateStart = parseWithLocalTimeOffset(`${dateStartString} ${time}`);
    const dateEnd = parseWithLocalTimeOffset(`${dateEndString} ${time}`);

    return (
      isEqual(resultDate, dateStart) ||
      isEqual(resultDate, dateEnd) ||
      (isAfter(resultDate, dateStart) && isAfter(dateEnd, resultDate))
    );
  });

  // set resultDate as next day after end day of holiday
  if (holiday) {
    const dateEnd = parseWithLocalTimeOffset(`${holiday.dateEnd} ${time}`);
    resultDate = add(dateEnd, { days: 1 });
  }

  // if holiday is found, check again updated date
  return holiday ? omitFreeDays(resultDate, holidays, time, now) : resultDate;
};

const getShipsUntilDate = (time: string, holidays: Holiday[] = [], now: Date = new Date()): [string, string] => {
  const formattedTime = time.replace('+', ' GMT+'); // fix for firefox
  let shipsUntil = parseWithLocalTimeOffset(`${getDateString(now)} ${formattedTime}`);

  if (isAfter(now, shipsUntil)) {
    shipsUntil = add(shipsUntil, { days: 1 });
  }

  shipsUntil = omitFreeDays(shipsUntil, holidays, formattedTime, now);

  const deliveryDate = omitFreeDays(add(shipsUntil, { days: 1 }), holidays, formattedTime, now);

  return [shipsUntil.toUTCString(), deliveryDate.toUTCString()];
};

const getDaysToDelivery = (time?: Nullable<string>, holidays: Holiday[] = []): number => {
  if (!isBrowser || !time) {
    return 0;
  }

  const [, deliveryDate] = getShipsUntilDate(time, holidays);

  return differenceInCalendarDays(new Date(deliveryDate), new Date());
};

export default getShipsUntilDate;
export { getDaysToDelivery };
